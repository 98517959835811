// Fix hover on btn-light button
.btn-light:hover {
    &:not(:disabled) {
        color: #212529;
        background-color: #e2e6ea;
        border-color: #dae0e5;
    }
}

.btn {
    font-weight: 500;
    &:hover {
        .badge {
            opacity: 0.65;
        }
    }
}

.list-autocomplete {
    .list-group-item {
        padding: 0.25rem 0.5rem;

        &:hover {
            background-color: lightgrey;
        }
    }
}

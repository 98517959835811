:root {
    // Notify
    --notify-background: rgba(255, 255, 255, .6);
    --notify-font-family: 'Material Icons';
    --notify-close-icon: '\e5cd';

    --percent-bg: .9;
    --percent-bg-hover: .6;
    --percent-shadow: .3;
    
    --notify-info: #8ab4f8;
    --notify-info-dark: #0d47a1;
    --notify-info-icon: var(--notify-info-dark);
    --notify-info-bg: rgba(138, 180, 248, var(--percent-bg));
    --notify-info-bg-hover: rgba(13, 71,161, var(--percent-bg-hover));
    --notify-info-shadow: rgba(13, 71,161, var(--percent-shadow));
    --notify-info-icon: '\e88e';

    --notify-success: #00C851;
    --notify-success-dark: #007E33;
    --notify-success-icon: var(--notify-success-dark);
    --notify-success-bg: rgba(129, 201, 149, var(--percent-bg));
    --notify-success-bg-hover: rgba(0, 126, 51, var(--percent-bg-hover));
    --notify-success-shadow: rgba(0, 126, 51, var(--percent-shadow));
    --notify-success-icon: '\e86c';
    
    --notify-warning: #fdd663;
    --notify-warning-dark: #ff8800;
    --notify-warning-icon: var(--notify-warning-dark);
    --notify-warning-bg: rgba(253, 214, 99 , var(--percent-bg));
    --notify-warning-bg-hover: rgba(255, 136, 0, var(--percent-bg-hover));
    --notify-warning-shadow: rgba(255, 136, 0, var(--percent-shadow));
    --notify-warning-icon: '\e000';

    --notify-error: #f28b82;
    --notify-error-dark: #CC0000;
    --notify-error-icon: var(--notify-error-dark);
    --notify-error-bg: rgba(242, 139, 130, var(--percent-bg));
    --notify-error-bg-hover: rgba(204, 0, 0, var(--percent-bg-hover));
    --notify-error-shadow: rgba(204, 0, 0, var(--percent-shadow));
    --notify-error-icon: '\e5c9';

    --autocomplete-list-color-hover: rgba(0, 0, 0, .1);

    --white-color-filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(276deg) brightness(101%) contrast(101%);
    --primary-color-filter: invert(28%) sepia(55%) saturate(6103%) hue-rotate(214deg) brightness(105%) contrast(98%);
    --success-color-filter: invert(36%) sepia(91%) saturate(379%) hue-rotate(100deg) brightness(97%) contrast(91%);
    --danger-color-filter: invert(31%) sepia(21%) saturate(6656%) hue-rotate(333deg) brightness(89%) contrast(93%);
    --warning-color-filter: invert(82%) sepia(37%) saturate(2767%) hue-rotate(354deg) brightness(101%) contrast(101%);
    --muted-color-filter: invert(49%) sepia(10%) saturate(378%) hue-rotate(166deg) brightness(91%) contrast(93%);
}
@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: local('Material Icons'), local('MaterialIcons-Regular'), url('../../assets/fonts/MaterialIcons-Regular.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'Material Icons TwoTone';
    font-style: normal;
    font-weight: 400;
    src: local('Material Icons TwoTone'), local('MaterialIcons-TwoTone'),
        url('../../assets/fonts/MaterialIconsTwoTone-Regular.otf') format('opentype');
    font-display: swap;
}

.material-icons,
.material-icons-two-tone {
    font-weight: normal;
    font-style: normal;
    font-size: 24px; /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;

    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;

    /* Support for IE. */
    font-feature-settings: 'liga';

    &.filter-white {
        filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(119deg) brightness(96%) contrast(109%);
    }

    &.filter-secodary {
        filter: invert(50%) sepia(3%) saturate(1467%) hue-rotate(167deg) brightness(89%) contrast(88%);
    }

    &.filter-primary {
        filter: invert(34%) sepia(62%) saturate(4772%) hue-rotate(209deg) brightness(100%) contrast(105%);
    }

    &.filter-disabled {
        filter: invert(95%) sepia(0%) saturate(1327%) hue-rotate(134deg) brightness(86%) contrast(91%);
    }
}

.material-icons {
    font-family: 'Material Icons';
}

.material-icons-outlined {
    font-family: 'Material Icons Outlined';
}

.material-icons-round {
    font-family: 'Material Icons Round';
}

.material-icons-two-tone {
    font-family: 'Material Icons TwoTone';
}

button > .icon {
    float: left;
    display: block;
    margin-right: 0.4em;
    width: 1.2em;
}
